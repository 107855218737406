<script setup lang="ts">
const { loadsPerMonth, alertsPerMonth, totalUsers } = storeToRefs(useMarketingStore());
</script>

<template>
  <div class="relative">
    <div class="mx-auto w-full max-w-7xl pb-20 pt-16 text-center lg:py-48 lg:text-left">
      <div class="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
        <h1 class="text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
          Get real time job alerts for
          <br class="xl:hidden">
          <span class="text-teal-700">Pilot Car Loads</span>
        </h1>
        <p class="mx-auto mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
          Pilot Car Loads gives you the power to set up load alerts based on location, rate, and much more. Stop getting alerts for jobs that don't apply to you.
        </p>

        <div class="mt-6 overflow-hidden">
          <dl class="-mx-8 -mt-8 flex flex-wrap justify-center lg:justify-start">
            <div
              v-if="loadsPerMonth"
              class="flex flex-col px-8 pt-8"
            >
              <dt class="order-2 text-lg font-medium text-gray-500">
                Loads posted <br><span class="text-sm">this month</span>
              </dt>
              <dd class="order-1 text-3xl font-extrabold text-teal-700 sm:text-3xl">
                {{ loadsPerMonth }}
              </dd>
            </div>
            <div
              v-if="alertsPerMonth"
              class="flex flex-col px-8 pt-8"
            >
              <dt class="order-2 text-lg font-medium text-gray-500">
                Load alerts sent <br><span class="text-sm">this month</span>
              </dt>
              <dd class="order-1 text-3xl font-extrabold text-teal-700 sm:text-3xl">
                {{ alertsPerMonth }}
              </dd>
            </div>
            <div
              v-if="totalUsers"
              class="flex flex-col px-8 pt-8"
            >
              <dt class="order-2 text-lg font-medium text-gray-500">
                Total users <br><span class="text-sm">registered</span>
              </dt>
              <dd class="order-1 text-3xl font-extrabold text-teal-700 sm:text-3xl">
                {{ totalUsers }}
              </dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
          <div class="rounded-md shadow">
            <nuxt-link
              to="/register"
              class="flex w-full items-center justify-center rounded-md border border-transparent bg-teal-700 px-8 py-3 text-base font-medium text-white hover:bg-teal-700 md:px-10 md:py-4 md:text-lg"
            >
              Join for free
            </nuxt-link>
          </div>
          <div class="mt-3 rounded-md shadow sm:ml-3 sm:mt-0">
            <nuxt-link
              to="/why-choose-us"
              class="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-teal-700 hover:bg-gray-50 md:px-10 md:py-4 md:text-lg"
            >
              Why choose us
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div class="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
      <img
        class="absolute inset-0 size-full object-cover"
        src="~assets/img/4.jpg"
        alt="Oversize Load and Escort Car"
      >
    </div>
  </div>
</template>
